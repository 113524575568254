import React, { useEffect, useState } from 'react'
import { AnalyticsProps } from '../AnalyticsSteps'
import { AddCircleOutline } from '@mui/icons-material'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import { useResponse } from '../../../Store/Hooks'
import { DataMap, defaultParams } from './ReverseMLUtils'
import Checkbox from '../../Checkbox'
import { Cron } from 'react-js-cron'
import 'react-js-cron/dist/styles.css'
import Dropdown from '../../Dropdown'
import ContentviewMessage from '../../ContentviewMessage'

const RedshiftWriteParams: React.FC<AnalyticsProps> = ({ _model, onSaveChanges, onCancel, stepIndex }) => {
    const { setWarningResponse } = useResponse()
    const [connectorName, setConnectorName] = useState('')
    const [dataSources, setDataSources] = useState<any[]>([])
    const [dataMapInputValue, setDataMapInputValue] = useState('')
    const [dataMapOutputValue, setDataMapOutputValue] = useState('')
    const [scheduled, setScheduled] = useState(false)
    const [params, setParams] = useState({ ...defaultParams, connectorType: 'redshift', connectorAction: 'write' })
    const [passwordEditable, setPasswordEditable] = useState(false)
    const [confirmPassword, setConfirmPassword] = useState({
        password: '',
    })

    useEffect(() => {
        if (_model.config.params.analytics && _model.config.params.analytics.length > 0) {
            const analytics = _model.config.params.analytics
            const readSources = analytics.filter(
                (analytic: any, index: number) =>
                    (analytic.params.connectorAction === 'read' || analytic.className === 'CustomAnalytic') &&
                    (stepIndex === -1 || index < stepIndex),
            )
            const sources = readSources.map(analytic =>
                analytic.label?.length > 0 ? analytic.label : analytic.className + ` ${analytic.params.connectorType ?? ''}`,
            )
            setDataSources(sources)
        }
    }, [_model])

    useEffect(() => {
        if (_model.config.params.analytics) {
            if (stepIndex > -1) {
                const _params = _model.config.params.analytics[stepIndex].params
                setConnectorName(_model.config.params.analytics[stepIndex].label)
                setParams({
                    ...params,
                    ..._params,
                })
                if (_params.schedule?.length > 0) {
                    setScheduled(true)
                }
            }
        }
    }, [stepIndex])

    if (!_model) {
        return null
    }

    const handleConnectorNameOnchange = (e: any) => {
        setConnectorName(e.target.value)
    }

    const handleConfirmPasswordFieldOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setConfirmPassword({ ...confirmPassword, [event.target.name]: event.target.value })
    }

    const handlePasswordEdit = () => {
        setPasswordEditable(true)
        let _params = { ...params }
        _params.password = ''
        setParams({
            ..._params,
        })
    }

    const handleDatasourceChange = (event: any) => {
        const selectedIndex = Number(event.target.value)
        setParams({ ...params, dataSource: dataSources[selectedIndex] })
    }

    const onAddDataMapEntry = () => {
        if (dataMapInputValue !== '' && dataMapOutputValue !== '') {
            let newDataMap: DataMap
            newDataMap = { input: dataMapInputValue, output: dataMapOutputValue }
            setParams({ ...params, dataMap: [...params.dataMap, newDataMap] })

            setDataMapInputValue('')
            setDataMapOutputValue('')
        }
    }

    const onDataMapInput = (e: any) => {
        const { name, value } = e.target
        if (name === 'datamap_input') {
            setDataMapInputValue(value)
        } else if (name === 'datamap_output') {
            setDataMapOutputValue(value)
        }
    }

    const onRemoveDataMapEntry = (index: number) => {
        let _f = [...params.dataMap]
        _f.splice(index, 1)
        setParams({ ...params, dataMap: _f })
    }

    const handleFieldsOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setParams({ ...params, [event.target.name]: event.target.value })
    }

    const onScheduledChange = (e: any) => {
        if (!e.target.checked) {
            setParams({ ...params, schedule: '' })
        }
        setScheduled(!scheduled)
    }

    const handleScheduleOnChange = (value: string) => {
        setParams({ ...params, schedule: value })
    }

    const onSubmit = () => {
        let _params = { ...params }
        if (
            params.authenticationType === 'arn' &&
            (connectorName.length === 0 ||
                _params.dataSource.length === 0 ||
                _params.arn.length === 0 ||
                _params.dbName.length === 0 ||
                _params.dbUser.length === 0 ||
                _params.clusterName.length === 0 ||
                _params.dbTable.length === 0 ||
                _params.dataMap.length === 0)
        ) {
            setWarningResponse(
                'Required Fields',
                `Please complete all required fields: ${[
                    'Connector Name',
                    'Data Source',
                    'Name',
                    'ARN',
                    'DB Name',
                    'DB User',
                    'Cluster Name',
                    'Data Table',
                    'Data Map',
                ].join(', ').replace(/, (?=[^,]*$)/, ' & ')}`,
            )
            return
        } else if (
            params.authenticationType === 'UPCredentials' &&
            (connectorName.length === 0 ||
                _params.dbName.length === 0 ||
                _params.dbUser.length === 0 ||
                _params.host.length === 0 ||
                _params.port.length === 0 ||
                _params.password.length === 0 ||
                _params.query.length === 0 ||
                _params.dbTable.length === 0 ||
                _params.dataMap.length === 0)
        ) {
            setWarningResponse(
                'Required Fields',
                `Please complete all required fields: ${[
                    'Connector Name',
                    'DB Name',
                    'DB User',
                    'Host',
                    'Port',
                    'Password',
                    'DB Table',
                    'Data Map',
                ].join(', ').replace(/, (?=[^,]*$)/, ' & ')}`,
            )
            return
        }

        if (
            (_model.id === 'new' && _params.password !== confirmPassword.password) ||
            (_model.id !== 'new' && passwordEditable && _params.password !== confirmPassword.password)
        ) {
            setWarningResponse(
                'Password Validation Error',
                `Ensure the following fields match: ${['Password', 'Confirm Password'].join(', ').replace(/, (?=[^,]*$)/, ' & ')}`,
            )
            return
        }

        if (_params.password === confirmPassword.password) {
            _params.password = params.password
        } else {
            _params.password = ''
        }

        onSaveChanges(connectorName, _params)
    }

    if (dataSources.length === 0) {
        return <ContentviewMessage title='No Data Source created' message='Please create a read data source connector first' />
    }

    return (
        <div className='flex flex-col gap-4'>
            <div>
                <label className='px-2 text-[13px] text-ai'>Select data source*</label>
                <Dropdown
                    selectedIndex={dataSources.indexOf(params.dataSource)}
                    placeholder='Choose analytic class'
                    items={dataSources}
                    handleDropdownChange={handleDatasourceChange}
                    size='w-1/2'
                />
            </div>
            <div>
                <label className='px-2 text-[13px] text-ai'>Name*</label>
                <input
                    className='input w-full'
                    type={'text'}
                    onChange={handleConnectorNameOnchange}
                    value={connectorName}
                    placeholder='Give this connector a name'
                    name='label'
                />
            </div>
            <hr className='border-ai-200' />
            <div>
                <p className='text-[13px] text-ai'>Authentication Type*</p>
                <div className='flex gap-5'>
                    <div className='relative flex items-center'>
                        <input
                            className='radio-parent peer'
                            name='authenticationType'
                            id='UPCredentials'
                            type='radio'
                            value='UPCredentials'
                            checked={params.authenticationType === 'UPCredentials'}
                            onChange={handleFieldsOnChange}
                        />
                        <div className='radio' />
                        <label htmlFor='UPCredentials' className='cursor-pointer truncate pl-3'>
                            User Credentials
                        </label>
                    </div>

                    <div className='relative flex items-center'>
                        <input
                            className='radio-parent peer'
                            name='authenticationType'
                            id='connectedApp'
                            type='radio'
                            value='arn'
                            checked={params.authenticationType === 'arn'}
                            onChange={handleFieldsOnChange}
                        />
                        <div className='radio' />
                        <label htmlFor='connectedApp' className='cursor-pointer truncate pl-3'>
                            ARN
                        </label>
                    </div>
                </div>
            </div>
            <div className='flex-1'>
                <label className='flex-1 px-2 text-[13px] text-ai'>DB Name</label>
                <input className='input w-full' type={'text'} onChange={handleFieldsOnChange} value={params.dbName ?? ''} name='dbName' />
            </div>
            <div className='flex-1'>
                <label className='flex-1 px-2 text-[13px] text-ai'>DB User</label>
                <input className='input w-full' type={'text'} onChange={handleFieldsOnChange} value={params.dbUser ?? ''} name='dbUser' />
            </div>
            {params.authenticationType === 'arn' && (
                <>
                    <div className='flex gap-2'>
                        <div>
                            <label className='px-2 text-[13px] text-ai'>ARN</label>
                            <input
                                className='input w-full'
                                type={'text'}
                                onChange={handleFieldsOnChange}
                                value={params.arn ?? ''}
                                name='arn'
                            />
                        </div>
                        <div className='flex-1'>
                            <label className='flex-1 px-2 text-[13px] text-ai'>Cluster Name</label>
                            <input
                                className='input w-full'
                                type={'text'}
                                onChange={handleFieldsOnChange}
                                value={params.clusterName ?? ''}
                                name='clusterName'
                            />
                        </div>
                    </div>
                </>
            )}
            {params.authenticationType === 'UPCredentials' && (
                <>
                    <div className='grid grid-cols-6 gap-2'>
                        <div className='relative top-1 col-span-full xl:col-span-3'>
                            <div className='flex justify-between'>
                                <label className='px-2 text-[13px] text-ai'>Password*</label>
                                {_model.id !== 'new' && !passwordEditable && (
                                    <label
                                        className='cursor-pointer px-2 text-[13px] text-ai-300 transition hover:text-ai hover:underline'
                                        onClick={handlePasswordEdit}
                                    >
                                        Edit
                                    </label>
                                )}
                            </div>
                            <input
                                className='input w-full'
                                type={'password'}
                                onChange={e => {
                                    if (params.password.length < 1 && confirmPassword.password.length > 0) {
                                        setConfirmPassword({ password: '' })
                                    }
                                    handleFieldsOnChange(e)
                                }}
                                value={params.password ?? ''}
                                name='password'
                                disabled={_model.id !== 'new' && !passwordEditable}
                            />
                        </div>
                        <div className='col-span-full xl:col-span-3'>
                            <label className='px-2 text-[13px] text-ai'>Confirm Password*</label>
                            <input
                                className='input w-full'
                                type={'password'}
                                onChange={handleConfirmPasswordFieldOnChange}
                                value={params.password ? confirmPassword.password : ''}
                                disabled={params.password.length < 1 || (_model.id !== 'new' && !passwordEditable)}
                                name='password'
                            />
                        </div>
                    </div>
                    <div className='flex gap-2'>
                        <div className='flex-1 '>
                            <label className='px-2 text-[13px] text-ai'>Host*</label>
                            <input
                                className='input w-full'
                                type={'text'}
                                onChange={handleFieldsOnChange}
                                value={params.host ?? ''}
                                name='host'
                            />
                        </div>
                        <div className='flex-1 '>
                            <label className='flex-1 px-2 text-[13px] text-ai'>Port*</label>
                            <input
                                className='input w-full'
                                type={'text'}
                                onChange={handleFieldsOnChange}
                                value={params.port ?? ''}
                                name='port'
                            />
                        </div>
                    </div>
                </>
            )}
            <div className='py-2'>
                <hr className='border-ai-200' />
                <p className='mt-3 font-bold text-ai-700'>Data Map</p>
                <p className='mb-3 text-[13px] text-ai'>
                    Use <span className='rounded-md bg-ai-200 py-[2px] px-2 font-bold'>" . "</span> for nested keys
                </p>
                <div className='flex flex-col gap-3 rounded-sm border border-ai-200 bg-ai-100 p-2'>
                    <div className='flex items-center gap-2'>
                        <>
                            <div className='flex-1'>
                                <input
                                    name='datamap_input'
                                    className='input w-full'
                                    type='text'
                                    placeholder='Input value'
                                    value={dataMapInputValue}
                                    onChange={onDataMapInput}
                                />
                            </div>
                            <div className='flex-1'>
                                <input
                                    name='datamap_output'
                                    className='input w-full'
                                    type='text'
                                    placeholder='Output value'
                                    value={dataMapOutputValue}
                                    onChange={onDataMapInput}
                                />
                            </div>
                        </>
                        <div>
                            <button
                                className=' btn-secondary relative flex h-[40px] items-center border border-ai p-3 text-[14px] font-bold'
                                onClick={onAddDataMapEntry}
                            >
                                <AddCircleOutline sx={{ fontSize: '16px' }} />
                                <span className='px-1'>Add</span>
                            </button>
                        </div>
                    </div>

                    <div>
                        <table aria-label='datasources' className='w-full border border-ai-200'>
                            <thead className='border-b border-b-ai-200 bg-white text-left'>
                                <tr>
                                    <th className='w-[5%] px-4 py-2' scope='col'>
                                        <span className='text-[16px] text-ai-800'>#</span>
                                    </th>
                                    <th className='w-[45%]'>
                                        <span className='text-[16px] text-ai-800'>Input</span>
                                    </th>
                                    <th className='w-[45%]'>
                                        <span className='text-[16px] text-ai-800'>Output</span>
                                    </th>
                                    <th className='w-[5%]'></th>
                                </tr>
                            </thead>
                            <tbody className='text-left'>
                                {params.dataMap.length === 0 ? (
                                    <tr>
                                        <td colSpan={4}>
                                            <div className='w-full p-6 text-center font-[16px] text-ai-800'>No data map available yet.</div>
                                        </td>
                                    </tr>
                                ) : (
                                    params.dataMap.map((dataMap: DataMap, index: number) => (
                                        <tr key={index} className={`${index % 2 === 0 ? 'bg-ai-50' : 'bg-ai-100'}`}>
                                            <td className='p-4'>
                                                <span className='text-[16px] text-ai-800'>{index + 1}</span>
                                            </td>
                                            <td>
                                                <span className='text-ai-800'>{dataMap.input}</span>
                                            </td>
                                            <td>
                                                <span className='text-ai-800'>{dataMap.output}</span>
                                            </td>
                                            <td className='pr-3'>
                                                <button
                                                    className='btn-icon'
                                                    onClick={() => onRemoveDataMapEntry(index)}
                                                    aria-label={`Remove filter ${index + 1}`}
                                                    title='Delete Row'
                                                >
                                                    <DeleteOutlineIcon color='error' />
                                                </button>
                                            </td>
                                        </tr>
                                    ))
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <hr className='border-ai-200' />
            {(_model.config.params.analytics?.length === 0 || stepIndex === 0) &&
                <>
                    <div className='flex flex-1 gap-2'>
                        <Checkbox checked={scheduled} onChange={onScheduledChange} />
                        <label className='block px-2 text-ai'>Schedule ETL?</label>
                    </div>
                    {scheduled && (
                        <div className='w-full'>
                            <Cron value={params.schedule} setValue={handleScheduleOnChange} />
                        </div>
                    )}
                </>
            }
            <hr className='border-ai-200' />
            {/* footer */}
            <div className='flex justify-between'>
                <button className='btn-primary' onClick={onSubmit}>
                    Save Changes
                </button>
                <button className='btn-secondary border-none bg-transparent text-[#FF0000]' onClick={onCancel}>
                    Cancel
                </button>
            </div>
        </div>
    )
}

export default RedshiftWriteParams
