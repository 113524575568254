import React from "react"
import { HarvesterProps } from "./HarvesterSteps"
import { useResponse } from "../../Store/Hooks"

const QueryParameterHarvesterParams: React.FC<HarvesterProps> = ({ _model, onSaveChanges, onRemoveClass }) => {

    const { setWarningResponse } = useResponse()
    const [params, setParams] = React.useState({
        empty: false,
        queryKey: '',
        urlLocations: '',
        attribute: ''
    })

    React.useEffect(() => {
        if (_model && _model.config?.params?.harvestingSteps && _model.config?.params?.harvestingSteps?.length > 0 && _model.config?.params?.harvestingSteps[0].className === 'QueryParameterHarvester') {
            setParams({ ..._model.config?.params?.harvestingSteps[0].params })
        }
    }, [])

    if (!_model) {
        return null
    }

    const onAllRecordsSwitch = () => {
        setParams({
            ...params,
            empty: !params.empty
        })
    }

    const handleFieldsOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setParams({ ...params, [event.target.name]: event.target.value })
    }

    const validateForm = () => {
        if(params.urlLocations && params.urlLocations !== '' && params.attribute === '') {
            return false
        }
        return true
    }

    const onSubmit = () => {
        if(!validateForm()) {
            setWarningResponse('Required Fields', `Please complete all required fields: attribute`)
            return
        }
        onSaveChanges(params)
    }

    return (
        <div className="flex flex-col gap-3">
            <div className="flex items-center gap-2">
                <p className="text-ai-800 text-[16px]">Empty</p>
                <div
                    className={`w-8 h-4 flex items-center rounded-full p-1 m-1 cursor-pointer ${
                        params.empty ? "bg-ai" : "bg-ai-200"
                    }`}
                    onClick={onAllRecordsSwitch}
                >
                    <div
                        className={`bg-white h-3 w-3 rounded-full shadow-md transform transition duration-150 ease-in-out ${
                            params.empty ? "translate-x-3" : null
                        }`}
                    ></div>
                </div>
            </div>
            <div className="w-full">
                <label htmlFor="query-key" className="block px-2 text-[13px] text-ai">Query key</label>
                <input id="query-key" className="input w-full" placeholder="Query key" onChange={handleFieldsOnChange} value={params.queryKey ?? ''} name="queryKey" />
            </div>
            <div className="flex gap-2">
                <div className="flex-1">
                    <label htmlFor="url-locations" className="block px-2 text-[13px] text-ai">URL Locations</label>
                    <input id="url-locations" className="input w-full" placeholder="URL Locations" onChange={handleFieldsOnChange} value={params.urlLocations ?? ''} name="urlLocations" />
                    <p className="text-ai-800 px-2 text-[14px]">Use query selectors</p>
                </div>
                <div className="flex-1">
                    <label htmlFor="attribute" className="block px-2 text-[13px] text-ai">Attribute</label>
                    <input id="attribute" className="input w-full" placeholder="Attribute" onChange={handleFieldsOnChange} value={params.attribute ?? ''} name="attribute" />
                    <p className="text-ai-800 px-2 text-[14px]">Required if using URL locations</p>
                </div>
            </div>
            <hr className="border-ai-200"/>
            <div className="flex justify-between">
                <button className="btn-primary" onClick={onSubmit}>Save Changes</button>
                <button className="btn-secondary border-none bg-transparent text-[#FF0000]" disabled={!_model.config.params.harvestingSteps || _model.config.params.harvestingSteps.length === 0} onClick={onRemoveClass}>Remove Class</button>
            </div>
        </div>
    )
}

export default QueryParameterHarvesterParams
